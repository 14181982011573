import {Connection, PublicKey} from "@solana/web3.js";
import {MarginAccount} from "../types";
import {MangoAccount, MangoAccountLayout} from "@blockworks-foundation/mango-client";

const MANGO = new PublicKey("mv3ekLzLbnVPNxjSKvqBpU3ZeZXPQdEC3bp5MDEBG68");
const SYSTEM = new PublicKey("11111111111111111111111111111111");

const getAccountInfo = async (pk: PublicKey) => {
    // console.log(
    //     "inside getAccount info"
    // )
    // const ankrEndpoint = "https://rpc.ankr.com/solana";
    // const qNode = "https://clean-neat-thunder.solana-mainnet.quiknode.pro/fb03b2c1931b2c02204fc26e6a884ff4adea4091/";
    const qNode = "https://purple-old-uranium.solana-mainnet.discover.quiknode.pro/539e7288c001603a4dcdd2f51e1d583ece0534d2/";
    const connection = new Connection(qNode, 'confirmed');
    const accountInfo = await connection.getAccountInfo(pk, 'confirmed');
    // console.log('accountInfo: ', accountInfo)
    return accountInfo
}

const fetchAccounts = async (address: string) => {
    // const walletAccountsEndpoint = "https://mango-transaction-log.herokuapp.com/v3/user-data/wallet-mango-accounts"
    const walletAccountsEndpoint = "https://mango-transactions-api.herokuapp.com/v3/user-data/wallet-mango-accounts"
    const response = await fetch(walletAccountsEndpoint + "?wallet-pk=" + address);

    // console.log('fetching accounts for: ', address);

    const data: [MarginAccount] = await response.json();

    // console.log('recvd margin accounts: ', data);

    if (!Array.isArray(data))
        return []

    const accounts = data.map(account => account.mango_account);

    return accounts
}

export const getMarginAccounts = async (address: string): Promise<string[]> => {
    // console.log('getting account info for : ', address)
    const pk = new PublicKey(address)
    const accountInfo = await getAccountInfo(pk)
    const owner = accountInfo?.owner

    if (owner?.equals(SYSTEM)) {

        const marginAccounts = await fetchAccounts(address)

        return marginAccounts
    } else if (owner?.equals(MANGO)) {

        const data = accountInfo?.data
        const layout = MangoAccountLayout.decode(data)
        const mangoAccount = new MangoAccount(pk, layout)
        const owner = mangoAccount.owner
        const marginAccounts = await fetchAccounts(owner.toString())

        return marginAccounts
    } else {

        return []
    }
}

export const shortenAddress = (address: string) => {
    const head = address.slice(0,5);
    const tail = address.slice(-5,);
    const result = head + "..." + tail;

    return result
}
export const validateAddress = (address: string) => {
    try {
        let _pubKey = new PublicKey(address);

        return true
    } catch {
        return false
    }
}

