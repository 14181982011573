import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import {Button, TextField} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {getMarginAccounts, shortenAddress, validateAddress} from "./utils";

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: 'Lato, sans-serif',
        },
    }
})

const AccountLogDownloadBtn = (props: {address: string}) => {
    const historyZipEndpoint = "https://mango-v3.s3.amazonaws.com/"
    const filename = props.address + "_data.zip"
    const logURL = historyZipEndpoint + filename

    return (
        <Grid p={1} xs={8} md={6}>
            <Button
                fullWidth={true}
                variant="outlined"
                size="large"
                endIcon={<FileDownloadOutlinedIcon />}
                component={Link}
                href={logURL}
                download={filename}
            >
                {shortenAddress(props.address)}
            </Button>
        </Grid>
    )
}

const MangoSpinner = () => {
    return (
        <CircularProgress color="primary" />
    )
}

function App() {
    const [address, setAddress] = useState("");
    const [marginAccounts, setMarginAccounts] = useState<string[]>([]);
    const [isValidAddress, setIsValidAddress] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const prevAddress = useRef("")

    useEffect(() => {
        const isValid = validateAddress(address)

        if (!isValid) {
            prevAddress.current = ""
            setIsValidAddress(false)
            setMarginAccounts([])
            return
        }

        if (prevAddress.current === address) {
            return
        }

        prevAddress.current = address
        setIsValidAddress(true)
        setIsFetching(true)
        getMarginAccounts(address).then(accounts => {
            setIsFetching(false)
            setMarginAccounts(accounts)
        })
    }, [address, JSON.stringify(marginAccounts)])

    const clearAddress = () => {
        setAddress("")
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                minHeight: "100vh", minWidth: "100vw",
                display: "flex", flexDirection: "column",
                backgroundColor: "#fdfdfd"
            }}>
                <Box flex={1}>
                    <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"}>
                        <Grid pt={1} mb={2}><Typography variant={"h3"}>Mango v3 Logs</Typography></Grid>
                        {!(isValidAddress) ?
                            <Grid sx={{color: "#7e7a90"}}>
                                <p>Enter a valid wallet address to show available logs.</p>
                            </Grid>
                            : null
                        }
                    </Grid>
                </Box>

                <Box flex={12} justifyContent={"center"} alignItems={"center"}>
                    <Grid container justifyContent={"center"} alignItems={"center"} px={2}>
                        <Grid container xs={12} md={5}>
                            <TextField variant={"outlined"}
                                       color={"primary"}
                                       disabled={(marginAccounts.length > 1) ? true : false}
                                       InputProps={{endAdornment: (
                                               <IconButton onClick={()=>clearAddress()}
                                                           sx={{visibility: address ? "visible" : "hidden"}}>
                                                   <ClearOutlinedIcon/>
                                               </IconButton>)}}
                                       fullWidth={true}
                                       label={"Wallet Address"}
                                       value={address}
                                       onChange={event => setAddress(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"center"} mt={2}>
                        <Grid container xs={12} bgcolor={"white"} px={2} justifyContent={"center"}>
                            {isValidAddress && !isFetching ?
                                <Grid xs={12} md={5} sx={{color: "#7e7a90"}}>
                                    <p>Found {marginAccounts.length} logs for {shortenAddress(address)}:</p>
                                </Grid>
                                : null
                            }
                        </Grid>

                        <Grid container xs={12} md={5}   justifyContent={"space-evenly"}>
                            {isValidAddress && marginAccounts.length < 1 ?
                                <Grid>{isFetching  ? <MangoSpinner /> : null}</Grid>
                                : marginAccounts.map(account => {
                                    return <AccountLogDownloadBtn key={account} address={account} />
                                })
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default App;
